.preloader::after {
    position: absolute;
    top: 0;
    bottom: 4%;
    right: 0;
    left: 0;

    content: " ";
    display: block;

    margin: auto;
    height: 32px;
    width: 32px;

    box-sizing: border-box;
    border: solid;
    border-width: 4px;
    border-radius: 50%;
    border-top-color: rgba(140, 140, 140, 0.55);
    border-bottom-color: rgba(140, 140, 140, 0.2);
    border-right-color: rgba(140, 140, 140, 0.2);
    border-left-color: rgba(140, 140, 140, 0.2);

    animation: rotating 0.9s linear infinite;
}

@keyframes rotating{
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}