:root {
    --background-color: #fff;
    --bar-fill: #4c4cff;
    --bar-width: 160px;
    --bar-height: 6px;
    --border-radius: 10px;
}

.progress-volume {
    position: relative;
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    height: var(--bar-height);
    width: var(--bar-width);
    opacity: 0.9;
}

.progress-volume:hover {
    opacity: 1;
    cursor: pointer;
}

.progress-volume_mobile {
    transform: rotate(-90deg);
}

.progress-done {
    background: var(--bar-fill);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.progress-volume input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border-radius: var(--border-radius);
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
}

/* Thumb: webkit */
.progress-volume input[type="range"]::-webkit-slider-thumb {
    width: 1px;
    visibility: hidden;
}

/* Thumb: Firefox */
.progress-volume input[type="range"]::-moz-range-thumb {
    width: 1px;
    visibility: hidden;
}