.player-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.player-controls .control-btn {
  margin-right: 30px;
}

.player-controls .control-btn:last-of-type {
  margin-right: unset;
}