.page-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.page-not-found__title {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 14px;
}

.page-not-found__reason {
    font-size: 16px;
}

.page-not-found__query {
    font-size: 16px;
    color: #9747FF;
}