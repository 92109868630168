.player-control-panel {
  display: flex;
  width: 180px;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transform: translateY(6px);
}

.player-control-panel .circle-button {
  transform: translateY(-12px);
}

.player-control-panel .tippy-content {
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  transform: translateX(24px);
  width: 60px;
  height: 190px;
}

.player-control-panel.main-page {
  transform: scale(1.43);
  justify-content: center;
}

.player-control-panel__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

@media (max-width: 1000px) {
  .player-control-panel {
    width: 100%;
    transform: unset;
  }
}