.circle-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: #2A2A54;
  border-radius: 40px;
  font-size: 0;
}