.plan {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  height: auto;
  padding: 20px 30px 40px 30px;
  box-sizing: border-box;
  border-radius: 40px;
  background-color: var(--dark-purple);
  border: 1px dashed transparent;
  transition: all .2s;
}

.plan:hover {
  border-color: var(--lilac);
  box-shadow: 0 0 16px rgba(51, 51, 255, 0.25), 0 0 8px rgba(51, 51, 255, 0.25), 0 4px 32px rgba(51, 51, 255, 0.5);
}

.plan .circle-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.plan__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.plan__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
}

.plan__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}

.plan__top > .rmd-icon {
  width: 125px;
  height: 125px;
}

.plan__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
}

/* .plan__advantages {
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style-type: none;
  padding: 12px 0;
  font-size: 15px;
  line-height: 1.3;
  text-align: center;
} */

.plan__separator {
  width: 26px;
  height: 2px;
  background: linear-gradient(63.18deg, #801AB3 0%, #4C4CFF 100%);
}

.plan__price {
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
}

.plan__more-about {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 3px;
  line-height: 1;
  color: white;
  cursor: help;
}

.plan__hint {
  border-radius: 16px;
}

.plan__hint .tippy-arrow {
  color: var(--lilac) !important;
}

.plan__hint .tippy-content {
  padding: 16px !important;
  background-color: #141437;
  border: 1px solid var(--lilac);
  border-radius: 16px;
  overflow: hidden;
}

.plan__hint .tippy-content span {
  font-size: 15px;
}

.plan__hint .tippy-content > div {
  display: flex;
  flex-direction: column;
  gap: 12px;
}