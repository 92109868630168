.control-btn {
    cursor: pointer;
}

.control-btn_primary .rmd-icon {
    width: 40px;
    height: 40px;
}

.control-btn_secondary .rmd-icon {
    width: 28px;
    height: 18px;
}