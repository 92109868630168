.header-account {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.header-account:has(> .preloader) .preloader::after {
    right: 10%;
    left: unset;
}

.header-account__email {
    font-size: 14px;
    line-height: 1;
    margin: 0;
}

.header-account__list {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 12px;
    gap: 14px;
}

.header-account__list-item {
    list-style-type: none;
    padding: 4px 0;
}

.header-account__list-item:only-of-type,
.header-account__list-item:last-of-type {
    border-bottom: unset;
}