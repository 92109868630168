.app {
    font-family: "JetBrainsMono", "Inter", "Arial", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: white;
    overflow: hidden;
    box-sizing: border-box;
    background-image: url("./assets/main-bg.svg");
    background-size: cover;
    background-position: center;
}

