.notification-list {
    position: fixed;
    top: 100px;
    right: 1px;
    height: calc(100% - 244px);
    bottom: 144px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: scroll;
    gap: 16px;
    padding-right: 8px;
    padding-bottom: 10px;
    z-index: 2;
    overflow: hidden;
}