.rmd-logo {
  width: 280px;
  height: 100%;
  font-size: 0;
  object-fit: cover;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  transform: translate(8%);
}

@media (max-width: 860px) {
  .rmd-logo {
    max-width: 120px;
    transform: translateY(5px);
  }
}