.sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -665px;
    width: 665px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    height: calc(100vh - 100px - 83px);
    background-color: #0E0E29;
    transition: all .5s;
    z-index: 15;
}

.sidebar__content {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 18px 18px 18px 28px;
    gap: 20px;
}

.sidebar__content::-webkit-scrollbar {
    width: 3px;
}

.sidebar__content:last-child {
    margin-bottom: 0;
}

.sidebar__content::-webkit-scrollbar-thumb {
    background-color: var(--inactive);
    border: unset;
    border-radius: 10px;
}

.sidebar_opened {
    right: 0;
}

@media (max-width: 1000px) {
    .sidebar {
        transform: translateY(-76px);
        height: calc(100vh - 256px);
    }
}

@media (max-width: 860px) {
    .sidebar {
        transform: translateY(-118px);
        height: calc(100vh - 226px);
    }
}