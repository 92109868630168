.home {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 72px;
}

.rmd-page .rmd-page__body:has(> .home) {
  height: calc(100vh - 100px - 306px);
}

@media (max-width: 1000px) {
  .home {
    padding: 0 20px;
  }
}

@media (max-width: 860px) {
  .home {
    justify-content: flex-start;
  }
}