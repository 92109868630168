@import url('./vendor/normalize.css');
@import url('./vendor/fonts/inter/inter.css');
@import url('./vendor/fonts/JetBrainsMono/index.css');
@import url('./vendor/fonts/Montserrat/index.css');

:root {
    --dark-purple: #141437;
    --lilac: #801AB2;
    --lilac-light: #9847FF;
    --inactive: #7978AA;
    --active: #4C4CFF;
    --red: #FF4545;
    --green: #78AA8C;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#root {
    --general-dark: #141437;
    background-color: var(--general-dark);
}

.body {
    font-family: "Inter", "Arial", sans-serif;
    color: #0F1419;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    overflow: hidden;
}

.custom-reset-btn {
    outline: none;
    border: none;
    cursor: pointer;
}