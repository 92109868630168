.tariffs-page {
  position: relative;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 60px;
  box-sizing: border-box;
  margin: 0 auto;
  gap: 40px;
}

.tariffs-page .equalizer-container {
  display: none;
}

.rmd-page .rmd-page__body:has(> .tariffs-page) {
  height: calc(100vh - 200px);
}
