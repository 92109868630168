.icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    padding: 0;
    margin: 0;
    background-color: transparent;
}

.icon-button.icon-button_inactive {
    cursor: no-drop;
}

.icon-button_inactive path {
    fill: var(--inactive);
}