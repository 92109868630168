.player-equalizer__fg {
    width: 100%;
    height: inherit;
    background: linear-gradient(180deg, rgba(20, 20, 55, 0) 0%, #0E0E29 100%);
    position: absolute;
    z-index: 1;
}

.player-equalizer__bg {
    position: absolute;
    z-index: 0;
}