.song-list {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 6px;
}

.song-list__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.song-list__title {
    font-size: 18px;
    line-height: 1.4;
    margin: 0;
}