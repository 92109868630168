.auth-logout-btn {
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    margin: 0;
    padding: 0;
    color: white;
    font-size: 15px;
    outline: none;
    border: none;
    transition: .2s;
    text-align: left;
}

.auth-logout-btn:hover {
    color: #4C4CFF;
}