.player-track-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.player-track-info__cover {
  width: 48px;
  height: 48px;
  border-style: none;
}

.player-track-info__cover img {
  width: 48px;
  height: 48px;
  object-fit: cover;
  object-position: center;
  font-size: 0;
}

.player-track-info__name {
  display: flex;
  flex-direction: column;
}

.player-track-info__artist {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0;
}

.player-track-info__song {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
}