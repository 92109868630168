.rmd-page {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.rmd-page:not(:has(> .rmd-page__footer)) {
    justify-content: flex-start;
}

.rmd-page__header {
    position: relative;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #141437;
    padding: 10px 20px;
    box-sizing: border-box;
}

.rmd-page__header-left,
.rmd-page__header-middle,
.rmd-page__header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    width: 33%;
}

.rmd-page__header-left {
    justify-content: flex-start;
}

.rmd-page__header-middle {
    justify-content: center;
    width: 34%;
}

.rmd-page__header-right {
    justify-content: flex-end;
}

.rmd-page__body {
  justify-self: flex-start;
}

.rmd-page__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;
    z-index: 10;
}

@media (max-width: 900px) {
    .rmd-page__header-left {
        width: 0;
    }

    .rmd-page__header-middle {
        width: 50%;
        justify-content: flex-start;
    }

    .rmd-page__header-right {
        width: 50%;
    }
}

@media (max-width: 1000px) {
    .rmd-page__footer  {
        height: auto;
    }
}

@media (max-width: 860px) {
    .rmd-page__header {
        height: 68px;
    }
    .rmd-page .rmd-page__body  {
        height: calc(220px);
    }
    .rmd-page:not(:has(> .rmd-page__footer)) .rmd-page__body {
        height: auto;
    }
}

@media (max-width: 780px) {
    .rmd-page__header {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 600px) {
    .account__email {
        font-size: 12px;
    }
}