.song {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding: 0 6px 0 2px;
    /* border-bottom: 1px solid #7978AA; */
    gap: 12px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid rgba(0,0,0,0);
    transition: border .2s;
    cursor: pointer;
}

.song:hover {
    border: 1px solid #7978AA;
}

.song__meta {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 75%;
}

.song__preview-pic {
    width: 28px;
    height: 28px;
    object-fit: cover;
    font-size: 0;
    margin: 0;
    padding: 0;
}

.song__description {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
}

.song__description p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
}

.song__title {
    font-size: 13px;
    font-weight: 300;
    color: #7978AA;
    width: min-content;
    max-width: 100px;
}

.song__artist {
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    width: fit-content;
    max-width: 270px;
}

.song__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}