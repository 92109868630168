@font-face {
    font-family: 'JetBrainsMono';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("JetBrainsMono-Thin.woff2?v=3.15") format("woff2");
}

@font-face {
    font-family: 'JetBrainsMono';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("JetBrainsMono-Light.woff2?v=3.15") format("woff2");
}

@font-face {
    font-family: 'JetBrainsMono';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("JetBrainsMono-Regular.woff2?v=3.15") format("woff2");
}

@font-face {
    font-family: 'JetBrainsMono';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("JetBrainsMono-Medium.woff2?v=3.15") format("woff2");
}

@font-face {
    font-family: 'JetBrainsMono';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("JetBrainsMono-SemiBold.woff2?v=3.15") format("woff2");
}

@font-face {
    font-family: 'JetBrainsMono';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("JetBrainsMono-Bold.woff2?v=3.15") format("woff2");
}

@font-face {
    font-family: 'JetBrainsMono';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("JetBrainsMono-ExtraBold.woff2?v=3.15") format("woff2");
}