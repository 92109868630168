.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(14, 14, 41, .9);
    overflow: hidden;
    transition: all .3s;
    z-index: 8;
}