.station__link-wrapper {
    width: inherit;
    text-decoration: none;
    background-color: transparent;
    color: white;
}

.station {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180px;
    padding: 40px 30px;
    border-radius: 20px;
    box-sizing: border-box;
    min-width: 80px;
    min-height: 80px;
    background-color: #141437;
    cursor: pointer;
    transition: .2s;
    list-style-type: none;
}

.station .rmd-icon {
    width: 72px;
    height: 72px;
    margin-bottom: 18px;
}

.station_active {
    box-shadow: 0 0 16px rgba(51, 51, 255, 0.25), 0 0 8px rgba(51, 51, 255, 0.25), 0 4px 32px rgba(51, 51, 255, 0.5);
    border: 1px dashed #4C4CFF;
}

.station_inactive {
    cursor: default;
    color: #7978AA;
}

.station__name {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
}

.station_active .station__name {
    background: linear-gradient(63.18deg, #801AB3 0%, #4C4CFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 860px) {
    .station {
        width: 140px;
        height: 140px;
    }
}