.player-track-details {
  width: 100%;
  color: #7978AA;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  margin-top: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}