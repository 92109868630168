.tippy-content {
  padding: 0;
}

.tippy-tooltip {
  background: rgba(20, 20, 55, 0.81);
  border: 0.5px solid #7F7F7F;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.4;
  padding: 6px 12px;
  color: #ffffff;
  font-weight: 500;
}

.tippy-tooltip.transparent-hover {
  color: #00090E;
  background: rgba(255, 255, 255, 0.65);
  border: 0.5px solid rgba(28, 28, 33, 0.5);
}