.string-input {
    height: 40px;
    border: none;
    border-bottom: 1px solid #7978AA;
    background-color: transparent;
    padding-left: 14px;
    color: white;
    outline: none;
    font-size: 15px;
}

.string-input::placeholder {
    color: #7978AA;
    font-size: 15px;
}

.string-input_error {
    border-bottom: 1px solid var(--red);
    color: var(--red);
}

.string-input_success {
    border-bottom: 1px solid var(--green);
    color: var(--green);
}