.form-text-link {
    font-size: 12px;
    color: var(--inactive);
    margin: 12px auto 20px auto;
    text-shadow: 0 4px 32px rgba(51, 51, 255, 0.50), 0 0 8px rgba(51, 51, 255, 0.25), 0 0 16px rgba(51, 51, 255, 0.25);
}

.form-text-link__link {
    color: var(--active);
    cursor: pointer;
}