.notification {
    position: relative;
    width: auto;
    max-width: 280px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: black;
    padding: 16px 42px 12px 20px;
    z-index: 2;
    font-size: 10px;
    gap: 8px;
    transition: all .3s;
    /* background-color: #141437; */
}

.notification_removed {
    transform: translateX(320px);
}

.notification__title {
    font-weight: 500;
    margin-bottom: 6px;
}

.notification__title_success {
    color: #78AA8C;
}

.notification__title_error {
    color: #FF4545;
}

.notification__title_default {
    color: #7978AA;
}

.notification__remove-btn {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
}

.notification__text {
    font-weight: 300;
    margin-bottom: 0;
    line-height: 1.5;
}