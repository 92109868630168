.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    width: 360px;
    height: auto;
    border-radius: 40px;
    background-color: #141437;
    color: white;
    z-index: 9;
}

.modal__close-btn {
    position: absolute;
    top: -40px;
    right: -40px;
    cursor: pointer;
}