.song-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
}

.song-controls .circle-button {
    margin-right: 12px;
}

.song-controls .circle-button:last-of-type {
    margin-right: unset;
}