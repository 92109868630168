.player-container__equalizer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 162px;
}

.player-container__body {
  background-color: #141437;
}

.player-container__body_regular {
  display: flex;
}

.player-container__body_small {
  display: block;
}

.player-container__track-info,
.player-container__section {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 33%;
}

.player-container__track-info {
  position: relative;
  justify-content: flex-start;
  gap: 14px;
}

.player-container__section {
  justify-content: flex-end;
  gap: 40px;
}

.player-container__main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 12px 6px;
  width: 100%;
}

@media (max-width: 1000px) {
  .player-container__equalizer {
    display: none;
  }

  .player-container__main {
    flex-direction: column;
    padding: 10px 0;
  }

  .player-container__body {
    height: auto;
    padding: 10px;
    box-sizing: border-box;
  }

  .player-container__track-info {
    display: none;
  }

  .player-container__volume-bar {
    display: none;
  }

  .player-container__section {
    justify-content: center;
    margin-top: 20px;
  }
}