.form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    box-sizing: border-box;
    font-size: 12px;
    font-family: "JetBrainsMono", "Inter", "Arial", sans-serif;
}

.form * {
    margin-bottom: 14px;
}

.form *:last-child {
    margin-bottom: 0;
}

.form__title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    color: white;
    text-align: center;
}