.button {
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    outline: none;
    cursor: pointer;
    background-color: unset;
    padding: unset;
    margin: 0;
    border: none;
    transition: all .2s;
    border-radius: 64px;
}

.type_primary {
    background: linear-gradient(63.18deg, #801AB3 0%, #4C4CFF 100%);
    color: white;
}

.type_primary:hover {
    opacity: .9;
}

.type_primary:active {
    background: linear-gradient(63.18deg, #4A006F 0%, #2222AF 100%);;
}

.type_secondary {
    background-color: #090920;
    border: 1px solid #7978AA;
    color: white;
}

.type_secondary:active {
    opacity: .9;
}

.type_secondary:hover {
    background-color: #7978AA;
}

.size_large {
    padding: 18px 24px;
}

.size_medium {
    padding: 12px 20px;
}

.size_small {
    padding: 6px 12px;
}

.type_mobile {
    font-size: 12px;
    color: white;
    line-height: 1;
    padding: 0;
    background-color: transparent;
    margin-right: 20px;

}

.type_mobile:last-of-type {
    margin-right: 10px;
}