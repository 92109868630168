.control-btn {
    position: relative;
}

.control-btn__like-count {
    position: absolute;
    top: -6px;
    right: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    color: white;
    font-weight: bold;
    font-size: 12px;
    background-color: var(--bar-fill);
    border-radius: 20px;
    z-index: 1;
}
